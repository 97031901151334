import React from "react";
import "./pages.css";
import clothing from "/Users/rakansinno/my-app/src/Assets/QClothing.mp4";
import promo from "/Users/rakansinno/my-app/src/Assets/QPromo.mp4";
import em from "/Users/rakansinno/my-app/src/Assets/QEm.mp4";

function About() {
  return (
    <div>
      <h1 className="AboutHead">Why Ultio</h1>
      <div className="About">
        <div className="AboutDetL">
          <div className="AboutDetTxt">
            <h1>Quality Clothing</h1>
            <h4>
              When was the last time you had a shirt that was not only
              comfortable and fashionable, but also durable? Most of the
              clothing brands now make cheap, low quality clothing that is not
              made to last.
            </h4>
            <h4>Ultio Crati is not one of those companies.</h4>
            <h4>
              We ensure our customers that any piece of clothing we sell you is
              in the upper echelon of clothing available on the market. More
              specifically, we pride ourselves in providing the best apparel and
              accessories.
            </h4>
          </div>
          <video autoPlay muted loop src={clothing} width="20%" />
        </div>
        <div className="AboutDetLR">
          <div className="AboutDetTxt">
            <h1>Quality Embellishment</h1>
            <h4>
              The way your products are embellished (printed, embroidered,
              stamped, heat pressed, etc.) is extremely important.
            </h4>
            <h4>
              If a cheap ink is used or an inferior quality machine is used to
              embellish your item, it could alter the durability of the design
              and possibly even the garment.
            </h4>
            <h4>
              That is why Ultio Crati ensures our clients that their designs
              will be embellished in the best way possible.
            </h4>
          </div>
          <video autoPlay muted loop src={em} width="20%" />
        </div>
        <div className="AboutDetL">
          <div className="AboutDetTxt">
            <h1>Quality Promotional Products</h1>
            <h4>Do you know where your pen is made? What about your mug?</h4>
            <h4>
              Ok………how about the folder holding your report that has to be
              submitted to your boss?
            </h4>
            <h4>
              Ultio Crati prides ourselves in being able to offer our customers
              premium promotional products and office supplies. That means they
              are made ethically and we strive to provide the best American and
              Turkish made products available.
            </h4>
          </div>
          <video autoPlay muted loop src={promo} width="20%" />
        </div>
      </div>
    </div>
  );
}

export default About;
