import React from "react";
import UCHome from "/Users/rakansinno/my-app/src/Assets/UC.mp4";
import "./pages.css";

function Home() {
  return (
    <>
      <div className="container">
        <div className="VidDiv">
          <video
            src={UCHome}
            autoPlay
            muted
            loop
            width="100%"
            height="100%"
          ></video>
        </div>
      </div>
    </>
  );
}

export default Home;
