import React from "react";
import "./pages.css";
import Wholesale from "./Images/Wholesale.png";
import { Link } from "react-router-dom";
import ClothingVid from "/Users/rakansinno/my-app/src/Assets/UCHome.mp4";
import GarmentVid from "/Users/rakansinno/my-app/src/Assets/UCGarment.mp4";
import MaterialsVid from "/Users/rakansinno/my-app/src/Assets/UCMaterials.mp4";

function Actions() {
  return (
    <div className="Actions">
      <h1 className="ActionsHead">What Can Ultio Do</h1>
      <div className="ActionsGrid">
        <div className="ActionsDetL">
          <div className="ActionsDetTxt">
            <h1>MATERIAL RESEARCH AND DEVELOPMENT</h1>
            <h4>
              NAICS Codes: 313110, 313240, 313310, 313320, 322211, 325211,
              325220, 325611, 541715
            </h4>
            <h3>
              Ultio Crati is always striving to move the market forward. We
              believe the best way to move forward is by focusing on developing
              affordable, durable and sustainable materials.
            </h3>
          </div>

          <video
            src={MaterialsVid}
            autoPlay
            muted
            loop
            width="50%"
            height="100%"
          ></video>
        </div>
        <div className="ActionsDetR">
          <div className="ActionsDetTxt">
            <h1>GARMENT MANUFACTURING</h1>
            <h4>
              NAICS Codes: 315110, 315120, 315190, 315210, 315220, 315240,
              315280, 315990, 323113, 424120
            </h4>
            <h3>
              We are a premium clothing, apparel and apparel accessory importer
              and manufacturer. If we aren’t able to make it in house, we work
              with our partners to ensure the best version of your product is
              being manufactured.
            </h3>
          </div>
          <video
            src={GarmentVid}
            autoPlay
            muted
            loop
            width="50%"
            height="100%"
          ></video>
        </div>
        <div className="ActionsDetL">
          <div className="ActionsDetTxt">
            <h1>CLOTHING AND PROMOTIONAL PRODUCT WHOLESALING</h1>
            <h4>
              NAICS Codes: 424120, 424350, 448110, 448120, 448130, 448150,
              453210, 453220
            </h4>
            <h3>
              We carry only the best products in the world, a lot of them are
              American made. In addition to our quality American made products,
              Ultio Crati offers premium foreign products that are made
              ethically.
            </h3>
          </div>
          <video
            src={ClothingVid}
            autoPlay
            muted
            loop
            width="50%"
            height="100%"
          ></video>
        </div>
      </div>
    </div>
  );
}

export default Actions;
