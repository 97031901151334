import React from "react";

import "./Footer.css";
import { FaLinkedin } from "react-icons/fa";
function LinkedIn() {
  return (
    <div className="LinkedIn">
      <a
        target="_blank"
        href="https://www.linkedin.com/company/ultio-crati-inc/"
      >
        <FaLinkedin className="LinkedinBTN" />
        <h5>Ultio Crati Inc.</h5>
      </a>

      <a
        className="NickLink"
        target="_blank"
        href="https://www.linkedin.com/in/nicholas-santana-47b3a415a/"
      >
        <FaLinkedin className="LinkedinBTN" />
        <h5>Nicholas Santana</h5>
      </a>
    </div>
  );
}

export default LinkedIn;
