import React from "react";
import "./Footer.css";
import { FaLinkedin } from "react-icons/fa";
import LinkedIn from "./LinkedIn";

function Footer() {
  return (
    <div className="footer">
      <div className="info">
        <h1>Ultio Crati Inc.</h1>
      </div>
      <div className="info">
        <h4>Nicholas Santana</h4>

        <LinkedIn />
        <div className="LinkedIn"></div>
      </div>
    </div>
  );
}

export default Footer;
