import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import "./Navbar.css";
import UC from "./Logo Transparent.png";
import EANGUS from "./EANGUS-Proposal-Final.pdf";

function Taskbar() {
  return (
    <>
      <div className="containerNav">
        <Link className="LogoLink" to="/">
          <img className="Logo" src={UC} />
        </Link>
        <nav className="nav">
          <ul>
            <CustomLink to="/About">Why Ultio</CustomLink>
            <CustomLink to="/Actions">What We Do</CustomLink>
            <a
              className="UC"
              href="https://ucmarketplace.xyz/"
              target="_blank"
              rel="noreferrer"
            >
              UC Marketplace
            </a>
          </ul>
        </nav>
      </div>
    </>
  );
}

function CustomLink({ to, children, ...props }) {
  const Rpath = useResolvedPath(to);
  const isActive = useMatch({ path: Rpath.pathname, end: true });
  return (
    <li className={isActive ? "active" : " "}>
      <Link to={to}>{children}</Link>
    </li>
  );
}

export default Taskbar;
