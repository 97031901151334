import React, { useState } from "react";

function Contact() {
  const [status, setStatus] = useState("Submit");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { name, email, message } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      message: message.value,
    };
    let response = await fetch("http://localhost:5000/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Submit");
    let result = await response.json();
    alert(result.status);
  };

  return (
    <div>
      <h1 className="ContactHead">Want to Contact Ultio Crati?</h1>

      <div className="ContactInput">
        <h2>Leave Your Name, Email, and A Brief Message!</h2>
        <div className="ContactInputGrid">
          <input
            type="text"
            id="name"
            className="EmailName"
            placeholder="Enter Email"
          ></input>
          <input
            type="email"
            id="email"
            className="EmailName"
            placeholder="Enter Full Name"
          ></input>
          <textarea
            className="Msg"
            type="text"
            id="message"
            placeholder="Enter Message"
            required
          />
          <button onClick={handleSubmit}>Submit</button>
        </div>
      </div>
    </div>
  );
}

export default Contact;
