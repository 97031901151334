import "./App.css";
import Footer from "./Footer/Footer";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Actions from "./Pages/Actions";
import Home from "./Pages/Home";
import Taskbar from "./Taskbar/Navbar";
import { Route, Routes } from "react-router-dom";
import "/Users/rakansinno/my-app/src/Pages/pages.css";
import { Helmet } from "react-helmet";
function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ultio Crati</title>
        <link rel="canonical" />
        <meta name="Ultio Crati" content="Ultio Crati" />
      </Helmet>
      <Taskbar />
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Actions" element={<Actions />} />
        </Routes>
      </div>
      <Footer className="footer" />
    </>
  );
}

export default App;
